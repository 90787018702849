import CompanyLeaveRoute from "@/modules/company/routers/leave/company_leave.router";
import CompanyAwayRoute from "@/modules/company/routers/away/company_away.router";
import TMSRoute from "../employees/tms.router";

const EmployeeList = () =>
  import("@/modules/company/components/employees/EmployeePage");
const EmployeeGroup = () =>
  import("@/modules/company/components/group/EmployeeGroupPage");
const EmployeeAdmin = () =>
  import("@/modules/company/components/admin/EmployeeAdminPage");

export default [
  {
    path: "/company/employee/list",
    name: "employeeList",
    component: EmployeeList,
  },

  {
    path: "/company/employee/group",
    name: "employeeGroup",
    component: EmployeeGroup,
  },
  {
    path: "/company/employee/admin",
    name: "employeeAdmin",
    component: EmployeeAdmin,
  },
  ...TMSRoute,
  ...CompanyLeaveRoute,
  ...CompanyAwayRoute,
];
