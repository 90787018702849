import ApiService from "@/core/services/api.service";

export default {
  /**
   * Submit employee personal leave application form.
   *
   * @param context
   * @param payload
   */
  submitPersonalLeaveApplicationForm(context, payload) {
    ApiService.setHeader();

    context.commit("setPersonalLeaveEntitlementFormBusy", true);
    return ApiService.post("company/leave/personal/application", payload.form)
      .then((res) => res)
      .finally(() =>
        context.commit("setPersonalLeaveEntitlementFormBusy", false)
      );
  },

  /**
   * Fetch personal leave entitlement form.
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  fetchPersonalLeaveEntitlementForm(context, payload) {
    ApiService.setHeader();

    return ApiService.query("company/leave/personal/entitlement/form", {
      params: {
        id: payload.id,
      },
    }).then((res) => {
      context.commit("setPersonalLeaveEntitlementForm", res.data);
      return res;
    });
  },
};
