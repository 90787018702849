const CompanyCalendarPage = () =>
  import("../../components/calendar/CompanyCalendarPage");
const CompanyDocumentPage = () =>
  import("../../components/document/CompanyDocumentPage");
const CompanyDocumentArchivedPage = () =>
  import("../../components/document/CompanyDocumentArchivedPage");
const CompanyInventoryPage = () =>
  import("../../components/inventory/CompanyInventoryPage");
const CompanyInventoryArchivedPage = () =>
  import("../../components/inventory/CompanyInventoryArchivedPage");
const CompanyInventoryCategoryPage = () =>
  import("../../components/inventory/CompanyInventoryCategoryPage");
const ABMPage = () => import("../../components/ABM/ABMPage");

export default [
  {
    path: "/company/calendar/list",
    name: "calendarList",
    component: CompanyCalendarPage,
  },
  {
    path: "/company/document/list",
    name: "docList",
    component: CompanyDocumentPage,
  },
  {
    path: "/company/document/archived",
    name: "docArchived",
    component: CompanyDocumentArchivedPage,
  },
  {
    path: "/company/inventory/list",
    name: "invList",
    component: CompanyInventoryPage,
  },
  {
    path: "/company/inventory/category",
    name: "invCategory",
    component: CompanyInventoryCategoryPage,
  },
  {
    path: "/company/inventory/archived",
    name: "invArchived",
    component: CompanyInventoryArchivedPage,
  },
  {
    path: "/company/abm/list",
    name: "ABMPage",
    component: ABMPage,
  },
];
