export default [
  {
    text: "SIDEBAR.MANAGEMENT",
    type: "menu-section",
    url: {},
    guard: {
      permissions: ["manage.employee"],
    },
  },
  {
    text: "SIDEBAR.EMPLOYEE",
    type: "menu-nav-parent",
    url: {
      path: "/company/employee",
    },
    guard: {
      permissions: ["manage.employee"],
    },
    icon: "/media/svg/icons/Communication/Group.svg",
    children: [
      {
        text: "DEVICEEMPLOYEE.LIST",
        type: "menu-nav",
        url: {
          name: "employeeList",
        },
        guard: {
          permissions: ["manage.employee"],
        },
        icon: null,
      },
      {
        text: "GROUP.EMPLOYEEGROUP",
        type: "menu-nav",
        url: {
          name: "employeeGroup",
        },
        guard: {
          permissions: ["manage.employee"],
        },
        icon: null,
      },
      {
        text: "Admin",
        type: "menu-nav",
        url: {
          name: "employeeAdmin",
        },
        guard: {
          permissions: ["manage.employee"],
        },
        icon: null,
      },
    ],
  },
  {
    text: "SIDEBAR.ATTENDANCE",
    type: "menu-nav",
    url: {
      name: "employeeAttendance",
    },
    icon: "/media/svg/icons/Home/Clock.svg",
    guard: {
      permissions: ["manage.employee"],
    },
    children: [
      {
        text: "Employee",
        type: "menu-nav",
        url: {
          name: "attendanceEmployee",
        },
        guard: {
          permissions: ["manage.employee"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
    ],
  },
  {
    text: "SIDEBAR.LEAVE",
    type: "menu-nav-parent",
    url: {
      path: "/company/leave",
    },
    guard: {
      permissions: ["manage.employee"],
    },
    icon: "/media/svg/icons/Communication/Group.svg",
    children: [
      {
        text: "LEAVE.DETAILS",
        type: "menu-nav",
        url: {
          name: "managementDetails",
        },
        guard: {
          permissions: ["manage.employee"],
        },
        icon: null,
      },
      {
        text: "LEAVE.ENTITLE",
        type: "menu-nav",
        url: {
          name: "managementEntitle",
        },
        guard: {
          permissions: ["manage.employee"],
        },
        icon: null,
      },
      {
        text: "LEAVE.AA",
        type: "menu-nav",
        url: {
          name: "companyLeaveAssignment",
        },
        guard: {
          permissions: ["manage.employee"],
        },
        icon: null,
      },
      {
        text: "LEAVE.APPROVAL",
        type: "menu-nav",
        url: {
          name: "managementApplication",
        },
        guard: {
          permissions: ["manage.employee"],
        },
        icon: null,
      },
    ],
  },
  {
    text: "SIDEBAR.AWAY",
    type: "menu-nav-parent",
    url: {
      path: "/company/away",
    },
    icon: "/media/svg/icons/Communication/Mail-box.svg",
    guard: {
      permissions: ["manage.employee"],
    },
    children: [
      {
        text: "Detail",
        type: "menu-nav",
        url: {
          name: "companyAwayDetailPage",
        },
        guard: {
          permissions: ["manage.employee"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
      {
        text: "Assignment",
        type: "menu-nav",
        url: {
          name: "employeeAwayAssignment",
        },
        guard: {
          permissions: ["manage.employee"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
      {
        text: "Application",
        type: "menu-nav",
        url: {
          name: "employeeAwayApplicationPage",
        },
        guard: {
          permissions: ["manage.employee"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
    ],
  },
  {
    text: "e-Filing",
    type: "menu-nav-parent",
    url: {
      path: "/company/away",
    },
    icon: "/media/svg/icons/Files/File.svg",
    guard: {
      permissions: ["manage.employee"],
    },
    children: [
      {
        text: "List",
        type: "menu-nav",
        url: {
          name: "docList",
        },
        guard: {
          permissions: ["manage.employee"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
      {
        text: "Archived",
        type: "menu-nav",
        url: {
          name: "docArchived",
        },
        guard: {
          permissions: ["manage.employee"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
    ],
  },
  {
    text: "Inventory",
    type: "menu-nav-parent",
    url: {
      path: "/company/away",
    },
    icon: "/media/svg/icons/Files/Folder.svg",
    guard: {
      permissions: ["manage.employee"],
    },
    children: [
      {
        text: "List",
        type: "menu-nav",
        url: {
          name: "invList",
        },
        guard: {
          permissions: ["manage.employee"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
      {
        text: "Category",
        type: "menu-nav",
        url: {
          name: "invCategory",
        },
        guard: {
          permissions: ["manage.employee"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
      {
        text: "Archived",
        type: "menu-nav",
        url: {
          name: "invArchived",
        },
        guard: {
          permissions: ["manage.employee"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
    ],
  },
  {
    text: "ABM",
    type: "menu-nav-parent",
    url: {
      path: "/company/away",
    },
    icon: "/media/svg/icons/Files/Folder.svg",
    guard: {
      permissions: ["manage.employee"],
    },
    children: [
      {
        text: "List",
        type: "menu-nav",
        url: {
          name: "ABMPage",
        },
        guard: {
          permissions: ["manage.employee"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
    ],
  },
  {
    text: "Calendar",
    type: "menu-nav-parent",
    url: {
      path: "/company/away",
    },
    icon: "/media/svg/icons/Files/Folder.svg",
    guard: {
      permissions: ["manage.employee"],
    },
    children: [
      {
        text: "List",
        type: "menu-nav",
        url: {
          name: "calendarList",
        },
        guard: {
          permissions: ["manage.employee"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
    ],
  },
];
