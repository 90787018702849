export default {
  GROUP: {
    EMPLOYEEGROUP: "Group",
    CREATEGROUP: "Create Group",
    CONF: "Attendance Configuration",
    TIMEIN: "Time-In",
    TIMECUTIN: "Time In Cut-In",
    TIMEOUT: "Time-Out",
    TIMECUTOUT: "Time Out Cut-Off",
    SAVE: "Save",
    UPDATE: "Update",

    MEMBERSHIP: {
      DELETESUCCESS: "Successfully removed employee from group",
      ADDSUCCESS: "Successfully added employee to group",
    },

    SCHEDULE: {
      DELETECONFIRM: "Delete group schedule?",
      DELETESUCCESS: "Group Schedule deleted",
      DELETEFAILED: "Failed to delete group Schedule",
      TABGENERAL: "General",
      BASE: "Schedule",
      PROMPT_SELECT: "Please Select Day Type",
      CLEAR: "Clear",
      START: "Start",
      END: "Akhir",
      TYPE: "Type",
      TYPEII: "Jenis",
      DELETE_CANCELLED: "Delete cancelled",

      DAYS: {
        MONDAY: "Monday",
        TUESDAY: "Tuesday",
        WEDNESDAY: "Wednesday",
        THURSDAY: "Thursday",
        FRIDAY: "Friday",
        SATURDAY: "Saturday",
        SUNDAY: "Sunday",
      },
    },

    GENERAL: {
      NAME: "Name",
      DESCRIPTION: "Description",
      FORM: {
        SUCCESS: "Successfully updated {group} general details",
        SUCCESS_TITLE: "Information updated",
        FAILED: "Failed to update {group} general details",
        FAILED_TITLE: "Gagal kemaskini",
      },
    },
  },
};
