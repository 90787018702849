import ApiService from "@/core/services/api.service";

const state = {
  leave: {},
  isSaved: false,
};

const actions = {
  /**
   *
   * @param context
   * @param form
   * @returns {*}
   */
  submitEmployeeAssignmentForm(context, form) {
    ApiService.setHeader();
    return ApiService.post("company/leave/employee/assignment", form);
  },
  /**
   *
   * @param context
   * @param payload
   */
  assignEmployeeLeaveAssignment(context, payload) {
    context.commit("setEmployeeLeaveAssignment", payload);
  },
  /**
   *
   * @param context
   * @param isSaved
   */
  assignEmployeeAssignmentFormIsSaved(context, isSaved) {
    context.commit("setEmployeeAssignmentFormIsSaved", isSaved);
  },
};

const mutations = {
  /**
   *
   * @param state
   * @param payload
   */
  setEmployeeLeaveAssignment(state, payload) {
    state.leave = payload;
  },
  /**
   *
   * @param state
   * @param isSaved
   */
  setEmployeeAssignmentFormIsSaved(state, isSaved) {
    state.isSaved = isSaved;
  },
};

const getters = {
  getEmployeeLeaveAssignmentDetails: (state) => state.leave,
  getEmployeeAssignmentFormIsSaved: (state) => state.isSaved,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
