import ApiService from "@/core/services/api.service";
import dayjs from "dayjs";

const state = {
  attendance: {},
  filter: {
    date: dayjs().format(),
  },
};

const actions = {
  fetchEmployeePersonalAttendanceMonthly(context) {
    ApiService.setHeader();
    return ApiService.query("employee/attendance/personal/monthly", {
      params: {
        date: state.filter.date,
      },
    })
      .then((response) => {
        console.log("Personal Att Monthly", response.data.data);
        context.commit(
          "setEmployeePersonalAttendanceMonthly",
          response.data.data
        );
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

const mutations = {
  setEmployeePersonalAttendanceMonthly(state, data) {
    console.log("Set personal attendance monthly ...");
    state.attendance = data;
  },

  setEmployeePersonalAttendanceMonthlyDateFilter(state, date) {
    console.log(date);
    state.filter.date = date;
  },
};

const getters = {
  getEmployeePersonalAttendanceMonthly: (state) => state.attendance,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
